@tailwind base;
@tailwind components;
@tailwind utilities;
@layer;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0; 
}

:root{
  --yellow: #f9c74f;
  --purple: #9d43dd;
}

.chart .title{
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin-left: auto;
  margin-right: auto;
}

.form-input{
  @apply mt-1 block w-full py-2 px-3 border border-gray-200 bg-white rounded-md outline-none focus:ring-indigo-500 sm:text-sm
}